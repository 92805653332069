import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import UserForm from './Form/UserForm'
import { DeleteUserWarning } from './AlertModal'
import FullScreenModal from './FullScreenModal'

const UserModal = props => {
  const close = () => {
    props.setShowUserModal(false)
    props.setEditUser(false)
    props.setSelectedUser({})
  }

  return (
    <FullScreenModal
      isOpen={props.showUserModal}
      close={close}
      header={
        props.editUser
          ? 'Edit User Id: ' + props.selectedUser['uid']
          : 'New User'
      }
    >
      <UserForm editUser={props.editUser} selectedUser={props.selectedUser} />
      <DeleteUserWarning />
    </FullScreenModal>
  )
}

const mapStateToProps = ({
  selectedUser,
  showUserModal,
  showDeleteUserWarningModal,
  editUser,
}) => ({
  selectedUser,
  showUserModal,
  showDeleteUserWarningModal,
  editUser,
})
export default connect(
  mapStateToProps,
  actions
)(UserModal)
