import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { PropTypes } from 'prop-types'
import { green, purple, grey, yellow, orange } from '@material-ui/core/colors'
import Popover from '@material-ui/core/Popover'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))

const GreenButton = withStyles(theme => ({
  root: {
    color: green[500],
    '&:hover': {
      backgroundColor: green[50],
    },
  },
}))(IconButton)

const OrangeButton = withStyles(theme => ({
  root: {
    color: orange[600],
    '&:hover': {
      backgroundColor: orange[50],
    },
  },
}))(IconButton)

const IconButtonPopOver = props => {
  const classes = useStyles()
  const [anchor, setAnchor] = useState(null)
  const handleClick = e => {
    setAnchor(e.currentTarget)
  }
  const handleClose = e => {
    setAnchor(null)
  }

  return (
    <div className={classes.root}>
      {' '}
      {props.color === 'green' ? (
        <GreenButton
          aria-owns={Boolean(anchor) ? 'simple-popper' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {props.icon}
        </GreenButton>
      ) : props.color === 'orange' ? (
        <OrangeButton
          aria-owns={Boolean(anchor) ? 'simple-popper' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {props.icon}
        </OrangeButton>
      ) : (
        <IconButton
          aria-owns={Boolean(anchor) ? 'simple-popper' : undefined}
          aria-haspopup="true"
          color={props.color}
          onClick={handleClick}
        >
          {props.icon}
        </IconButton>
      )}
      <Popover
        id="simple-popper"
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {props.popoverChild}
      </Popover>
    </div>
  )
}

IconButtonPopOver.defaultProps = {
  color: 'default',
}

IconButtonPopOver.propTypes = {
  icon: PropTypes.any,
  color: PropTypes.string,
  popoverChild: PropTypes.any,
}

export default IconButtonPopOver
