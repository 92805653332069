import React from 'react'
import styled from 'styled-components'
import loaderIcon from '../../assets/svg-loaders/oval.svg'
import loaderBlackIcon from '../../assets/svg-loaders/oval-black.svg'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'

const Loader = styled.img`
  width: 40px;
  border: 5px solid #424858;
  background-color: #424858;
  z-index: 1;
  border-radius: 50%;
  position: fixed;
  right: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
`

const SubLoader = styled.img`
  width: 22px;
`

class TableLoaderClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <Loader src={loaderIcon} />
  }
}

class SubmitLoaderClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <SubLoader src={loaderBlackIcon} />
  }
}

export const TableLoader = connect(actions)(TableLoaderClass)

export const SubmitLoader = connect(actions)(SubmitLoaderClass)
