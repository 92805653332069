import React from 'react'
import { Button } from 'react-bootstrap'
import ExcelJS from 'exceljs'

function extractUidMapping(groupsObject) {
  const result = {}
  Object.keys(groupsObject).forEach(key => {
    const group = groupsObject[key]
    if (Array.isArray(group.uids) && group.uids.length > 0) {
      group.uids.forEach(uid => {
        if (!result[uid]) {
          result[uid] = []
        }
        result[uid].push({
          oid: group.oid,
          name: group.name,
          sid: group.sid,
        })
      })
    }
  })

  return result
}

export const ExportUsersToExcel = ({ dataArray, rid, groups }) => {
  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet 1')
    // Set column headers
    worksheet.columns = [
      { header: 'User ID', key: 'uid', width: 35 },
      { header: 'First Name', key: 'firstName', width: 45 },
      { header: 'Last Name', key: 'lastName', width: 30 },
      { header: 'Role', key: 'role', width: 45 },
      { header: 'Group ID', key: 'groupID', width: 45 },
      { header: 'Group Name', key: 'groupName', width: 45 },
      { header: 'Store ID', key: 'sid', width: 45 },
      { header: 'Description', key: 'desc', width: 45 },
      { header: 'Secondary ID', key: 'sec_uid', width: 45 },
    ]
    const uidsMapping = extractUidMapping(groups)

    dataArray.forEach(data => {
      const userGroups = uidsMapping[data.uid]
      if (userGroups) {
        if (userGroups.length > 1) {
          userGroups.forEach(group => {
            worksheet.addRow({
              uid: data.uid,
              firstName: data.first_name,
              lastName: data.last_name,
              role: data.user_group_role,
              groupID: group.oid,
              groupName: group.name,
              sid: group.sid?.join('\n'),
              desc: data.desc ?? '',
              sec_uid: data.sec_uid ?? '',
            })
          })
        } else {
          const group = userGroups[0]
          worksheet.addRow({
            uid: data.uid,
            firstName: data.first_name,
            lastName: data.last_name,
            role: data.user_group_role,
            groupID: group.oid,
            groupName: group.name,
            sid: group.sid?.join('\n'),
            desc: data.desc ?? '',
            sec_uid: data.sec_uid ?? '',
          })
        }
      } else {
        worksheet.addRow({
          uid: data.uid,
          firstName: data.first_name,
          lastName: data.last_name,
          role: data.user_group_role,
          groupID: '',
          groupName: '',
          sid: '',
          desc: data.desc ?? '',
          sec_uid: data.sec_uid ?? '',
        })
      }
    })

    worksheet.eachRow(row => {
      row.eachCell(cell => {
        cell.alignment = {
          wrapText: true,
          vertical: 'top',
          horizontal: 'center',
        }
        cell.font = { size: 14 }
      })
    })

    // Set fill color for first column
    const firstColumn = worksheet.getColumn(1)
    firstColumn.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC0C0C0' }, // Light gray color
    }
    firstColumn.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black color
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    }
    firstColumn.font = { bold: true, size: 16, color: { argb: 'FF000000' } }

    // Set fill color and border for header row
    const headerRow = worksheet.getRow(1)
    headerRow.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC0C0C0' },
      }

      // Set font style for header
      cell.font = { bold: true, size: 16, color: { argb: 'FF000000' } }

      // Add border to the cell
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } }, // Black color
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      }
    })

    // Increase header row height
    headerRow.height = 30

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer()

    // Create blob and download
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = `${rid}_users.xlsx`
    link.click()

    // Cleanup
    URL.revokeObjectURL(url)
  }

  return (
    <Button
      variant="primary"
      onClick={handleExport}
      style={{
        border: '1px solid #b7b7ff',
        borderRadius: '10px',
        color: '#b7b7ff',
      }}
    >
      Export to CSV
    </Button>
  )
}
