import React, { useEffect } from 'react'
import { connect } from 'redux-zero/react/index'
import actions from '../../store/actions'
import { getGroups } from '../../services/api'
import { isNil } from 'lodash'
import { isSolid } from '../../utils/taskHandler'
import {
  getRetailConfig,
  getRetailSIDS,
  getRetailDataConfigs,
} from '../../services/api'

const withGroups = WrappedComponent => {
  const WithGroups = props => {
    const handleResponse = response => {
      if (response.status === 401) {
        props.logout()
      } else if (response.status === 200) {
        if (isSolid(Object.keys(response.groups))) {
          // props.setSelectedGroup(
          //   response.groups[Object.keys(response.groups)[0]]
          // )
        }
        props.setGroups({ ...response.groups })
      }
    }

    const handleRetailResponse = response => {
      if (response.status === 401) {
        props.logout()
      } else if (response.status === 200) {
        props.setStoreDepartments(response.config.retail_segments_by_levels)
        props.setRetailConfig(props.selected_rid, response.config)
        props.setAppTaskTypes(
          props.retailsConfig[props.selected_rid] &&
            props.retailsConfig[props.selected_rid].dynamic_task_views !==
              undefined
            ? props.retailsConfig[props.selected_rid].dynamic_task_views
            : []
        )
      }
    }
    const handleRetailDataConfigResponse = response => {
      if (response.status === 401) {
        props.logout()
      } else if (response.status === 200) {
        props.setRetailDataConfigs(props.selected_rid, response)
      }
    }
    const handleDataConfigsResponse = response => {
      if (response.status === 401) {
        props.logout()
      } else if (response.status === 200) {
        props.setStoresIds(response, props.groups)
      }
    }
    const _getDataConfigs = () => {
      getRetailSIDS(
        handleDataConfigsResponse,
        props.selected_rid,
        props.hideFullScreenLoader
      )
      getRetailDataConfigs(handleRetailDataConfigResponse, props.selected_rid)
    }

    const _getRetailConfig = () => {
      props.showFullScreenLoader()
      getRetailConfig(
        handleRetailResponse,
        props.selected_rid,
        props.hideFullScreenLoader
      )
    }
    const handleGroupsSelect = (event, val) => {
      let groupId = null
      if (!isNil(val)) {
        groupId = val.value
      }
      props.setSelectedGroup(props.groups[groupId])
      props.handleTaskFetcherStoreSelect(groupId)
      props.setPosts([])
    }

    const getRetailGroups = () => {
      props.showFullScreenLoader()
      getGroups(handleResponse, props.selected_rid, props.hideFullScreenLoader)
    }

    const getGroupNameFromRasID = ras_id => {
      if (isSolid(ras_id)) {
        const groupId = ras_id.substring(ras_id.indexOf('-') + 1)
        const group = props.groups[groupId]
        return isSolid(group) && isSolid(group['name'])
          ? group['name']
          : 'can find the name'
      } else {
        return 'Not set Yet, try to refresh'
      }
    }

    useEffect(() => {
      if (isSolid(props.selected_rid)) {
        getRetailGroups()
        _getRetailConfig()
        _getDataConfigs()
      }
    }, [props.selected_rid])

    return (
      <WrappedComponent
        {...props}
        handleGroupsSelect={handleGroupsSelect}
        getRetailGroups={getRetailGroups}
        getGroupNameFromRasID={getGroupNameFromRasID}
      />
    )
  }

  const mapStateToProps = ({
    selected_rid,
    users,
    groups,
    selectedGroup,
    loading,
    posts,
    retailsConfig,
    setDynamicTaskTypesView,
    taskTypesView,
    retailTags,
  }) => ({
    selected_rid,
    users,
    groups,
    selectedGroup,
    loading,
    posts,
    retailsConfig,
    setDynamicTaskTypesView,
    taskTypesView,
    retailTags,
  })

  return connect(mapStateToProps, actions)(WithGroups)
}

export default withGroups
