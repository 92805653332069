import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import styled from 'styled-components'
import Button from './Button'
import Input from '../Elements/FormElements/Input'
import Select from '../Elements/FormElements/Select'
import { SubmitLoader } from './TableLoader'
import Copy from '../../assets/svg-icons/copy.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Colors from '../../utils/Colors'
import './styles/masterKeyStyle.css'
import { getMasterKey } from '../../services/api'
import { printOptions } from '../../utils/userHandler'
import { printStoresOptions } from '../../utils/taskHandler'
import Keys from '../../utils/Keys'
const appUrl = process.env.MYSTORE_APP_URL
const ButtonS = styled(Button)`
  display: flex;
  margin: 0.4rem;
`

const Massage = styled.p`
  color: red;
  margin: 5px;
  font-size: 0.7rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

class MasterKeyGenerator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      masterKey: '',
      selected_sid: this.props.selected_sid,
      loading: false,
      locale: 'en',
      ttl: 10,
      permission: 1,
      showMassage: false,
    }
  }

  handleSelect = event => {
    this.setState({
      [event.target.name]: event.target.value,
      showMassage: false,
      masterKey: '',
    })
  }

  createKey = () => {
    const { selected_sid, locale, permission, ttl } = this.state
    return `${
      this.props.selected_rid
    }-${selected_sid}-${locale}-${permission}-${ttl}`
  }

  findMasterKey = masterKeyObject => {
    return this.props.masterKeys.find(element => {
      return element.key === masterKeyObject.key
    })
  }

  handleResponse = (response, masterKeyObject) => {
    if (response !== undefined && response['master_store_key'] !== undefined) {
      this.setState({
        masterKey: `${appUrl}?id=${response['master_store_key']}`,
        loading: false,
      })
      masterKeyObject['masterKey'] = this.state.masterKey
      this.props.setMasterKeys(masterKeyObject)
    } else {
      this.props.handleAlert({
        text: 'some thing is wrong :(',
        type: Keys.ALERT_ERROR,
      })
      this.setState({ loading: false })
    }
  }

  generateMasterKey = () => {
    const { selected_sid, permission, locale, ttl } = this.state

    let _permission = permission
    if (Number(ttl) > 10) {
      _permission = 1
    }

    let masterKeyObject = {
      key: this.createKey(),
      sid: selected_sid,
      rid: this.props.selected_rid,
      permission: _permission,
      locale: locale,
      ttl: Number(ttl),
    }
    let requestedMasterKey = this.findMasterKey(masterKeyObject)
    if (requestedMasterKey === undefined) {
      this.setState({ loading: true })
      getMasterKey(this.handleResponse, masterKeyObject)
    } else {
      this.setState({
        masterKey: requestedMasterKey.masterKey,
        showMassage: true,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="masterKeyListItem">
          <div className="masterKeyRow">
            <Select
              label={'store'}
              width={100}
              name={'selected_sid'}
              defaultValue={
                this.props.selected_sid !== null ? this.props.selected_sid : ''
              }
              onChange={e => this.handleSelect(e)}
            >
              {printStoresOptions(
                this.props.userRidsDetails,
                this.props.selected_rid
              )}
            </Select>
            <Row>
              <Select
                label={'App language'}
                defaultValue={this.props.locale}
                name={'locale'}
                onChange={this.handleSelect}
                required
              >
                {printOptions(this.props.locales, 'value')}
              </Select>

              <Select
                label={'Permission'}
                defaultValue={this.state.permission}
                onChange={this.handleSelect}
                name={'permission'}
                disabled={this.state.ttl > 10}
              >
                {printOptions(
                  this.props.permissions,
                  'index',
                  this.state.ttl > 10
                    ? ['BASE', 'EDIT', 'ADMIN']
                    : ['BASE', 'ADMIN']
                )}
              </Select>
            </Row>
            <Row>
              <Input
                type={'number'}
                onChange={this.handleSelect}
                name={'ttl'}
                value={this.state.ttl}
                min={1}
                max={14}
                width={'100%'}
                label={'TTL'}
              />
            </Row>
            <Row>
              <ButtonS bg={Colors.ACTION} onClick={this.generateMasterKey}>
                {this.state.loading ? <SubmitLoader /> : 'Generate'}
              </ButtonS>
            </Row>
          </div>
        </div>

        <div className="masterKeyListItem">
          <div className="masterKeyRow">
            <Input
              className={'formInputMK'}
              type="text"
              value={this.state.masterKey}
              readOnly
              label={'Master Key'}
              width={'100%'}
            />

            <CopyToClipboard
              text={this.state.masterKey}
              onCopy={() =>
                this.props.handleAlert({
                  text:
                    'you just copied the master key \n\n a, b, c, easy as 1, 2, 3...',
                  type: Keys.ALERT_SUCCESS,
                })
              }
            >
              <button className="copyButton">
                <img className={'icon'} src={Copy} />
              </button>
            </CopyToClipboard>
          </div>
        </div>
        {this.state.showMassage ? (
          <Massage>
            this master keys was all ready called in the session{' '}
          </Massage>
        ) : null}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({
  tasks,
  userRidsDetails,
  selected_rid,
  selected_sid,
  locales,
  permissions,
  masterKeys,
}) => ({
  tasks,
  userRidsDetails,
  selected_rid,
  selected_sid,
  locales,
  permissions,
  masterKeys,
})

export default connect(
  mapStateToProps,
  actions
)(MasterKeyGenerator)
