import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { PropTypes } from 'prop-types'
import { green, purple, grey, yellow, orange } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))

const GreenButton = withStyles(theme => ({
  root: {
    color: green[500],
    '&:hover': {
      backgroundColor: green[50],
    },
  },
}))(IconButton)

const YellowButton = withStyles(theme => ({
  root: {
    color: grey[900],
    '&:hover': {
      backgroundColor: yellow[50],
    },
  },
}))(IconButton)

const OrangeButton = withStyles(theme => ({
  root: {
    color: orange[600],
    '&:hover': {
      backgroundColor: orange[50],
    },
  },
}))(IconButton)

const IconButtonWrapper = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {props.color === 'green' ? (
        <GreenButton onClick={props.onClick}>{props.icon}</GreenButton>
      ) : props.color === 'orange' ? (
        <OrangeButton onClick={props.onClick}>{props.icon}</OrangeButton>
      ) : (
        <IconButton color={props.color} onClick={props.onClick}>
          {props.icon}
        </IconButton>
      )}
    </div>
  )
}

IconButtonWrapper.defaultProps = {
  color: 'default',
}

IconButtonWrapper.propTypes = {
  icon: PropTypes.any,
  color: PropTypes.string,
  onClick: PropTypes.func,
}

export default IconButtonWrapper
