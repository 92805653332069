import React from 'react'
import UsersTable from '../components/Table/UsersTable'
import Layout from '../components/layout'
import UserModal from '../components/Elements/UserModal'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.css'
import FullScreenLoader from '../components/FullScreenLoader'
import withRetailUsers from '../components/Elements/withRetailUsers'
import Add from '@material-ui/icons/Add'
import Key from '@material-ui/icons/VpnKeyRounded'
import IconButtonWrapper from '../components/Elements/Buttons/IconButton'
import IconButtonPopOver from '../components/Elements/Buttons/IconButtonPopOver'
import MasterKeyGenerator from '../components/Elements/MasterKeyGenerator'
import withGroups from '../components/Elements/withGroups'

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  margin: 10px;
`

const UsersPage = props => {
  return (
    <React.Fragment>
      <Layout>
        <HeaderStyled>
          <h1>Users</h1>
          <IconButtonWrapper
            color={'green'}
            onClick={() => {
              props.setEditUser(false)
              props.setShowUserModal(true)
            }}
            icon={<Add style={{ fontSize: 25 }} />}
          />
          <IconButtonPopOver
            popoverChild={<MasterKeyGenerator />}
            color={'orange'}
            icon={<Key style={{ fontSize: 25 }} />}
          />
        </HeaderStyled>
        <br />
        <br />

        <FullScreenLoader loading={props.loading} />

        <UsersTable />
      </Layout>
      <UserModal />
    </React.Fragment>
  )
}

export default withGroups(withRetailUsers(UsersPage))
