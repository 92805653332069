import React, { Component, useState } from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import { timestamp2tableDate, epochTime2date } from '../../utils/dateHandler'
import {
  convertUnderscoreNameToLabel,
  formatAvatarPath,
  generateID,
  isSolid,
} from '../../utils/taskHandler'
import './style.css'
import Keys from '../../utils/Keys'
import { getUser } from '../../services/api'
import { isNil } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '../Elements/FormElements/Avatar'
import Input from '../Elements/FormElements/Input'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { ExportUsersToExcel } from '../../components/ExportUsersToCSV'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    position: 'inherit',
    '& > *': {
      margin: 'auto',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))

const headerStyle = {
  textAlign: 'center',
  align: 'center',
  alignItems: 'center',
  alignContent: 'center',
  dataAlign: 'center',
  fontWeight: 'bold',
}

const UsersTable = props => {
  const classes = useStyles()
  const [searchQuery, setSearchQuery] = useState('')

  const getUserIndex = uid => {
    let _index = null
    props.users.forEach((user, index) => {
      if (user[Keys.UID] === uid) _index = index
    })
    return _index
  }

  const handleResponse = (response, index) => {
    if (response.status === 401) {
      props.logout()
    } else if (response.status === 200) {
      props.setFetchedUsers(response.user['uid'], response.user)
      props.setSelectedUser({ ...response.user, index: index })
      props.setEditUser(true)
      props.setShowUserModal(true)
    } else if (response.status === 403 || response.status === 404) {
      props.handleAlert({
        text: 'No such user',
        type: Keys.ALERT_ERROR,
      })
    }
  }

  const handelRowClick = async row => {
    props.showFullScreenLoader()
    await getUser(
      handleResponse,
      row[Keys.UID].trim(),
      getUserIndex(row[Keys.UID]),
      props.hideFullScreenLoader
    )
  }

  const _avatar = (cell, row) => {
    const { users } = props
    const uid = row[Keys.UID]
    const currentUser = users.find(user => user.uid === uid)
    return <Avatar className={classes.root} avatar={currentUser} />
  }

  const searchResults = () => {
    const { users } = props
    const searchQueryLower = searchQuery.toLowerCase()
    return users.filter(user => {
      return (
        user[Keys.UID].toLowerCase().includes(searchQueryLower) ||
        (!isNil(user[Keys.FIRST_NAME]) &&
          user[Keys.FIRST_NAME].toLowerCase().includes(searchQueryLower)) ||
        (!isNil(user[Keys.LAST_NAME]) &&
          user[Keys.LAST_NAME].toLowerCase().includes(searchQueryLower)) ||
        (!isNil(user[Keys.DESCRIPTION]) &&
          user[Keys.DESCRIPTION].toLowerCase().includes(searchQueryLower)) ||
        (!isNil(user[Keys.SECONDARY_UID]) &&
          user[Keys.SECONDARY_UID].toLowerCase().includes(searchQueryLower))
      )
    })
  }

  const options = {
    paginationSize: 4,
  }

  const columns = [
    {
      dataField: Keys.UID,
      text: 'User ID',
      headerStyle: headerStyle,
      align: 'center',
      sort: true,
    },
    {
      dataField: Keys.FIRST_NAME,
      text: 'First name',
      headerStyle: headerStyle,
      align: 'center',
      sort: true,
    },
    {
      dataField: Keys.LAST_NAME,
      text: 'Last name',
      headerStyle: headerStyle,
      align: 'center',
      sort: true,
    },
    {
      dataField: Keys.DESCRIPTION,
      text: 'Description',
      headerStyle: headerStyle,
      align: 'center',
      sort: true,
    },
    {
      dataField: Keys.SECONDARY_UID,
      text: 'Secondary UID',
      headerStyle: headerStyle,
      align: 'center',
      sort: true,
    },
    {
      dataField: 'avatar',
      text: 'Avatar',
      isDummyField: true,
      headerStyle: headerStyle,
      align: 'center',
      formatter: _avatar,
    },
  ]

  const selectRow = {
    mode: 'radio',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      handelRowClick(row, isSelect, rowIndex, e)
    },
  }

  return (
    <div className="tableWrapper">
      {!isSolid(props.users) ? (
        'Nothing to Show'
      ) : (
        <>
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <ExportUsersToExcel
                dataArray={props.users}
                rid={props.selected_rid}
                groups={props.groups}
              />

              <Input
                onChange={event => {
                  setSearchQuery(event.target.value)
                }}
                label={'search'}
                value={searchQuery}
              />
            </div>
            <BootstrapTable
              pagination={paginationFactory(options)}
              keyField="uid"
              data={searchQuery.trim() !== '' ? searchResults() : props.users}
              selectRow={selectRow}
              columns={columns}
              bordered={false}
            />
          </React.Fragment>
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({
  users,
  permissions,
  userRoles,
  userRidsDetails,
  selected_rid,
  selected_sid,
  avatar_path,
  cdn,
  fetchedUsers,
  groups,
}) => ({
  users,
  permissions,
  userRoles,
  userRidsDetails,
  selected_rid,
  selected_sid,
  avatar_path,
  cdn,
  fetchedUsers,
  groups,
})

export default connect(mapStateToProps, actions)(UsersTable)
